/* eslint-disable import/first */
import React, { useState } from "react"
// import qs from "qs"
import { useLocation } from "@reach/router"
import GlobalLayout from "../../../GlobalLayout";
import {
    MYACCOUNT_SEARCH_RESULT_SALES,
    MYACCOUNT_SEARCH_RESULT_LETTINGS
} from "../../../../../constants/urls";

import { withSubtheme } from "../../../../../StarberryComponentsMui"
import Container from '@mui/material/Container';
import Flexbox from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '../../../progress/CircularProgress';
import clsx from 'clsx';
import { StarberryIcons } from '../../../icons';
// import PropertyGrid from "../components-mui/PropertyGrid";
import SearchTitle from '../hits/Title';
import {
    UserObjectStoreProvider,
    useUserObjectState,
    useAuthState
} from "../../../../../services"

// algolia
import {
    InstantSearch,
    connectStateResults,
    Configure,
    connectCurrentRefinements
} from "react-instantsearch-dom"

// Filter
import RefineFilter from '../filter/';
import CustomSortBy from "../filter/SortBy";
import LayoutSwitch from "../filter/layoutSwitch"
import SaveSearch from  '../../../PropertyResult/SaveProperty';
import CustomInfiniteHits from "../hits/InfiniteHits"
import CustomStats from "../hits/Stats"
import NoResults from "../hits/Stats/NoResults"
import { searchClient, DEBOUNCE_TIME, INDEX_NAME } from "../config"

import {
    sortByOptions
} from "../filter/constants"

import { createURL, searchStateToUrl, urlToSearchState, propertyH1, parseSearchUrl } from "../functions";
import { LinkItem } from '../../../helper/myaccountLink';

import defaults from '../defaults';

const LoadingIndicator = connectStateResults((props) => {
    const { isSearchStalled, searchResults } = props
    return (
        (isSearchStalled && !searchResults) ? <CircularProgress /> : null
    )}
);

const ClearRefinements = ({ items, refine }) => (
    <Button classes={{root: "search-clear-btn"}} variant="text" size="sm" disableRipple={true} onClick={() => refine(items)} disabled={!items.length}>
        <CloseIcon color="inherit" fontSize="inherit" /> Clear filters
    </Button>
)
const CustomClearRefinements = connectCurrentRefinements(ClearRefinements);

/*  Search Start */
// Ref - https://www.algolia.com/doc/guides/building-search-ui/going-further/routing-urls/react/
const AlgoliaSearch = withSubtheme(props => {
    const {
        className,
        loadMoreBtnProp,
        loadPrevBtnProp,
        loadMoreBtnLabel,
        loadPrevBtnLabel,
        filterBtnProp,
        titleType,
        titleProps,
        titleStatsProps,
        gridViewProps,
        mapViewProps,
        propertyType,
        searchTypeLinkProps,
        showClearFilters,
        filterIconHeader,
        showSaveSearch,
    } = props

    const search_type = propertyType
    // get location
    const location = useLocation()
    const [searchState, setSearchState] = useState(
        urlToSearchState(location, search_type)
    )
    const [debouncedSetState, setDebouncedSetState] = useState(null)

    const onSearchStateChange = React.useCallback(searchState => {
        clearTimeout(debouncedSetState)
        setDebouncedSetState(
            setTimeout(() => {
                /**
                 TODO: pagination retain prev page data working when using following
                 1. window.history.pushState Ref: https://developer.mozilla.org/en-US/docs/Web/API/History/pushState#syntax
                 2. react history.pushState
                 Ref for above :https://www.algolia.com/doc/guides/building-search-ui/going-further/routing-urls/react/#basic-urls

                 Since gatsby use navigate (instead of history, window) from @reach/router, here we need to use navigate but not working when using gatsby navigate
                 Ref: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/#replace-history-during-programmatic-navigation
                 */
                // Finally using window.history.pushState
                window.history.pushState({pageID: search_type, searchState:searchState, pathname:searchStateToUrl(location, searchState)}, search_type, searchStateToUrl(location, searchState));
                // const urlTo = searchStateToUrl(location, searchState);
                // if (!urlTo.match(/page\-/)) {
                //     navigate(
                //         urlTo,
                //         // {
                //         //     state: searchState,
                //         //     replace: true
                //         // }
                //         searchState
                //     )
                // }

            }, DEBOUNCE_TIME)
        )
        setSearchState(searchState)
    }, [location, searchState, search_type]);

    const searchParams = parseSearchUrl(searchState);
    const h1 = propertyH1(searchParams);

    return (
        <InstantSearch
            indexName={INDEX_NAME} // Search Index Name
            searchClient={searchClient} // Search Client
            searchState={searchState} // current state of the search
            onSearchStateChange={onSearchStateChange} // This function is called every time the search state is updated.
            createURL={createURL} // Change the url based on search
            routing="true"
            // resultsState
        >

            <div className={className}>
                <div className="search-topbar">
                    {showClearFilters &&
                        <div className="search-clear">
                            <CustomClearRefinements />
                        </div>
                    }
                    {searchTypeLinkProps?.show &&
                        <div className={clsx("search-switch", searchTypeLinkProps?.showAsToggle && "search-switch-toggle")}>
                            <LinkItem to={MYACCOUNT_SEARCH_RESULT_SALES} className={search_type === 'sales' ? `Mui-selected` : ''}>Sales</LinkItem>
                            <LinkItem to={MYACCOUNT_SEARCH_RESULT_LETTINGS} className={search_type === 'lettings' ? `Mui-selected` : ''}>Lettings</LinkItem>
                        </div>
                    }
                </div>
                <RefineFilter search_type={search_type} filterBtnProp={filterBtnProp} filterIconHeader={filterIconHeader} />
                {/*<InfiniteHits />*/}
                {/* <CustomInfiniteHits />*/}
                <Configure hitsPerPage={12} />
                <div className="search-panel__info">
                    <SearchTitle title={h1} titleProps={titleProps} titleType={titleType} customStats={<CustomStats showOnlyNumber={true} />}>
                        {("showResultsNumber" !== titleType) &&  <CustomStats titleStatsProps={titleStatsProps} />}
                    </SearchTitle>
                    <div className="search-panel__info__links">
                        <div className="sortyby-select">
                            <CustomSortBy
                                defaultRefinement={INDEX_NAME}
                                items={sortByOptions}
                            />
                        </div>
                        {showSaveSearch && (
                            <div>
                                <SaveSearch type="search" searchParams={searchParams} />
                            </div>
                        )}
                        <LayoutSwitch search_type={search_type} gridViewProps={gridViewProps} mapViewProps={mapViewProps} location={location} searchState={searchState} />
                    </div>
                </div>
                <div className="algolia_results">
                    <LoadingIndicator />
                    <NoResults titleStatsProps={titleStatsProps} />
                    <CustomInfiniteHits
                        loadMoreBtnProp={loadMoreBtnProp}
                        loadPrevBtnProp={loadPrevBtnProp}
                        loadMoreBtnLabel={loadMoreBtnLabel}
                        loadPrevBtnLabel={loadPrevBtnLabel}
                    />
                </div>
            </div>
        </InstantSearch>
    )
}, "propertyResultsPageFilters", defaults)

/* Search End */

const PropertyResultWrapper = ({search_type, filterIconHeader}) => {
    const { services } = useUserObjectState()
    const { services: authServices } = useAuthState()
    const isAuthenticated = authServices.isAuthenticated()

    const [filterOpen, setFilterOpen] = React.useState(false);
    const handleChange = (val) => {
        setFilterOpen(!val);
    };

    const pageRightDrawer = filterIconHeader ? <IconButton onClick={() => handleChange(filterOpen)}><StarberryIcons iconName="filterResults" /></IconButton> : null;
    React.useEffect(() => {
        if (isAuthenticated) {
            // on page load get the users saved objects
            services.getUserobject({ state:true })
        }
        authServices.updateState({pageTitle:`Search Results`, pageRightCustomComponent:pageRightDrawer, pageRightDrawer: null, pageLeftIcon: false});
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Open filter component
    React.useEffect(() => {
        authServices.updateState({pageTitle:`Search Results`, pageRightCustomComponent:pageRightDrawer, openSearchFilter:filterOpen, pageRightDrawer: null, pageLeftIcon: false});
    }, [filterOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container maxWidth="xl">
            <Flexbox container spacing={0}>
                <Flexbox item xs={12}>
                    <AlgoliaSearch propertyType={search_type} filterIconHeader={filterIconHeader} />
                </Flexbox>
            </Flexbox>
        </Container>
    )
}
//
// const SearchResultPageThemeWrap = withSubtheme(props => {
//     const {
//         search_type,
//         filterIconHeader,
//     } = props
//
//     return (
//         <UserObjectStoreProvider>
//             <PropertyResultWrapper search_type={search_type} filterIconHeader={filterIconHeader} />
//         </UserObjectStoreProvider>
//     )
// }, "propertyResultsPage")
//
// const SearchResultPage = (props) => {
//     return (
//         <GlobalLayout>
//             <SearchResultPageThemeWrap {...props} />
//         </GlobalLayout>
//     )
// }

export default React.memo(PropertyResultWrapper)
