/**
// 1. Create a React component
const RangeSelect = () => {
  // return the DOM output
};

// 2. Connect the component using the connector
const CustomRangeSelect = connectRange(RangeSelect);

// 3. Use your connected widget
<CustomRangeSelect />
*/
// Ref: https://www.algolia.com/doc/api-reference/widgets/range-input/react/#full-example

import React from "react"
import { Select, FormControl, MenuItem} from "@mui/material"

const RangeSelectMax = props => {
    const {
        // currentRefinement,
        // min,
        // max,
        // precision,
        // refine,
        attribute,
        updateFilterParams,
        value,
        options,
        className,
        variant="outlined",
        fullWidth=false
    } = props

    let minVal = 0;
    let maxVal = 0;

    let itemKey, itemVal;
    let customOptions = options
    if (minVal !== undefined) {
        customOptions = options.filter(x => {
            Object.keys(x).forEach(key => {
                itemKey = key
                itemVal = x[key]
            })
            if (!itemKey) return true
            if (parseInt(itemKey) > parseInt(minVal)) return true
            return false
        })
    }

    return (
        <FormControl fullWidth={fullWidth}>
            <Select
                variant={variant}
                displayEmpty={true}
                value={value || ""}
                onChange={event => {
                    updateFilterParams(attribute, event.target.value);
                }}
                className={className}
            >
                {customOptions.map((item, i) => {
                    Object.keys(item).forEach(key => {
                        itemKey = key
                        itemVal = item[key]
                    })
                    return <MenuItem key={i} value={itemKey}>{itemVal}</MenuItem>
                })}
            </Select>
        </FormControl>
    )
}

export default RangeSelectMax
