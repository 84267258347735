import React from "react"
import { orderBy } from 'lodash';
import { Highlight, connectRefinementList } from "react-instantsearch-dom"

import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"

const RefinementList = ({
    items,
    isFromSearch,
    refine,
    searchForItems,
    createURL,
    attribute,
}) => {

    items = orderBy(items, ['count'], ['desc'])
    const handleChange = (e, value) => {
        e.preventDefault()
        refine(value)
    }
    return (
        <>
            {items.map(item => (
                <FormControlLabel
                    sx={{
                        "textTransform": "capitalize"
                    }}
                    control={
                        <Checkbox
                            checked={item.isRefined}
                            onChange={e => handleChange(e, item.value)}
                            name={attribute}
                            value={item.label}

                        />
                    }
                    label={`${item.label}`}
                />
            ))}
        </>
    )
}

const CustomRefinementList = connectRefinementList(RefinementList)

export default CustomRefinementList
