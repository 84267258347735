/**
// 1. Create a React component
const MenuSelect = () => {
 // return the DOM output
};

// 2. Connect the component using the connector
const CustomMenuSelect = connectMenu(MenuSelect);

// 3. Use your connected widget
<CustomMenuSelect />
*/
// Ref: https://www.algolia.com/doc/api-reference/widgets/menu-select/react/#full-example

import React from "react"

import { connectMenu } from "react-instantsearch-dom"

import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"

function sentenceCase (str) {
  if ((str===null) || (str===''))
       return false;
  else
   str = str.toString();

 return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
}

const MenuSelect = props => {
    const { items, currentRefinement, refine, options, className } = props
    let itemLists = items
    if (!itemLists.length)
        itemLists = options
    let itemKey, itemVal;

    return (
        <Select
            variant="outlined"
            displayEmpty={true}
            value={currentRefinement || ""}
            onChange={event => refine(event.target.value)}
            className={className}
        >
            <MenuItem key={`-1`} value={``}>{`All Properties`}</MenuItem>
            {itemLists.map((item, i) => {
                itemVal = (item.label).toLowerCase()
                itemKey = sentenceCase(item.label)
                return <MenuItem key={i} value={itemVal}>{itemKey}</MenuItem>
            })}
        </Select>
    )
}

const CustomMenuSelect = connectMenu(MenuSelect)

export default CustomMenuSelect
