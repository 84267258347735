import React from "react"
import Typography from '@mui/material/Typography';
import { connectStats } from "react-instantsearch-dom"

const Title = (props) => {
    const { children, title, titleProps, titleType, customStats, nbHits } = props

    const newTitle = React.useMemo(() => {
        if (nbHits === 1) {
            const replaceData = {
                Properties: "Property",
                properties: "property"
            };
            return title.replace(/Properties|properties/gi, function(matched){
                return replaceData[matched];
            });
        }

        return title;
    }, [title, nbHits]);

    if (title) {
        return (
            <div className="search-panel__info__title">
                <Typography {...titleProps} classes={{root: "search-panel__info__title__title"}}>
                    {("showResultsNumber" === titleType) && <>{customStats} </>}{newTitle}
                </Typography> {children}
            </div>
        );
    }

    return null;
}

const SearchTitle = connectStats(Title)

export default SearchTitle
