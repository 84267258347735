import React from "react"
import Skeleton from "@mui/material/Skeleton"

const AutoHeightSkeleton = ({ aspectRatio, ...props }) => {
    const ref = React.useRef();
    const [height, setHeight] = React.useState(0);

    React.useEffect(() => {
        if (ref.current) {
            const handleHeight = () => {
                if (ref.current) {
                    setHeight(ref.current.getBoundingClientRect().width * aspectRatio);
                }
            }

            handleHeight();

            window.addEventListener('resize', handleHeight);

            return () => document.removeEventListener('resize', handleHeight);
        }
    }, []);

    return <Skeleton ref={ref} variant="rectangular" {...props} height={height} />;
};

export default AutoHeightSkeleton;
