export default {
    "props": {
        "titleProps": {
            "component": "p",
            "variant": "h6",
            "color": "textPrimary",
        },
        "messageProps": {
            "color": "textPrimary",
        },
        "iconProps": {
            // "color": "textPrimary", // disabled this because Failed prop type: Invalid prop `color` of value `textPrimary` supplied to `ForwardRef(SvgIcon)`, expected one of ["action","disabled","error","inherit","primary","secondary"].
            "fontSize": "large",
        },
        "showMessage": true,
    },
    "sx": (theme) => ({
        "display": "flex",
        "flexDirection": "column",
        "alignItems": "center",
        "padding": theme.spacing(4,2),
        "opacity": 0.35,
        "textAlign": "center",
        [theme.breakpoints.up('md')]: {
            "padding": theme.spacing(8,2),
        },
        "& .noActions-icon": {

        },
        ...(theme.props.showBoxContainer && {
            "alignItems": "start",
            "opacity": 1,
            "marginBottom": theme.spacing(4),
            [theme.breakpoints.up('md')]: {
                "padding": 0,
            },
        }),
    }),
}