import React from 'react';
import GoogleMapReact from 'google-map-react';

/**
 * Assets
 */

// import './styles/_index.scss';

const Map = ({markers, center, zoom, searchFilter, setMapObj, searchValue}) => {

  const handleClick = (e) => {
    if (!e.target.closest('.marker') && !e.target.classList.contains('marker')) {
      document.querySelectorAll('.marker').forEach(item => item.classList.remove('active'));
    }
  }

  const getMapBounds = (map, maps, places) => {
    const bounds = new maps.LatLngBounds();

    places.forEach((place) => {
      bounds.extend(new maps.LatLng(
        place.lat,
        place.lng,
      ));
    });
    return bounds;
  };

  const codeAddress = (map, maps, places) => {
    return new Promise((resolve, reject) => {
      const geocoder = new maps.Geocoder();
      geocoder.geocode( { 'address': searchValue}, function(results, status) {
        if (status === 'OK') {
          const postal_town = results[0].address_components.filter(item => item.types.includes("postal_town")).pop().short_name
          searchFilter(postal_town)
          setMapObj(r => ({
            ...r,
            search: results[0].geometry.location
          }))
          resolve();
        }
      });
    })
  }

  const apiIsLoaded = async (map, maps, places) => {
    const bounds = getMapBounds(map, maps, places);
    await codeAddress(map, maps, places)
    map.fitBounds(bounds);
    bindResizeListener(map, maps, bounds);
    setMapObj(r => ({...r, map, maps}));
  };

  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds);
      });
    });
  };

  const Marker = () => {
      return (
          <>test...</>
      )
  }

  return (
    <div className="map" onClick={handleClick} onKeyDown={handleClick} role="presentation">
      <GoogleMapReact
          yesIWantToUseGoogleMapApiInternals
          bootstrapURLKeys={{
            key: process.env.GOOGLE_API || '',
            libraries:['places', 'geometry']
          }}
          defaultCenter={center}
          defaultZoom={zoom}
          // onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, markers)}
      >
        {markers.map((marker,ind) => <Marker key={ind} lat={marker.lat} lng={marker.lng} text={marker.title} />)}
      </GoogleMapReact>
    </div>
  )
}

export default Map;
