/**
// 1. Create a React component
const InfiniteHits = () => {
  // return the DOM output
};

// 2. Connect the component using the connector
const CustomInfiniteHits = connectInfiniteHits(InfiniteHits);

// 3. Use your connected widget
<CustomInfiniteHits />
*/
// Ref: https://www.algolia.com/doc/api-reference/widgets/infinite-hits/react/#full-example

import React, { useEffect } from "react";
import { get } from 'lodash';
import Button from "@mui/material/Button";
import PropertyGrid from "../../../../../../PropertyGrid";

import {
    useMyaccountState
} from "../../../../../../../services"

const InfiniteHits = ({
    hits = [],
    hasPrevious,
    refinePrevious,
    hasMore,
    refineNext,
    hasNext,
    onNext,

    // custom passed props
    loadMoreBtnProp,
    // loadPrevBtnProp,
    loadMoreBtnLabel,
    // loadPrevBtnLabel
}) => {

    // const { state, services } = useMyaccountState();
    // const hits = get(state, 'properties', []);
    //
    // useEffect(() => {
    //     services.getProperties({_limit: 1})
    // }, [])

    const ref = React.useRef(hits);

    const hitsAreTheSame = React.useMemo(() => {
        return hits.map((x, i) => {
            return x.crm_id === ref.current[i]?.crm_id;
        }).reduce((acc, o) => acc && o, true) && hits.length === ref.current.length;
    }, [hits, ref.current]);

    const result = React.useMemo(() => {
        if (!hitsAreTheSame) {
            return hits;
        } else {
            return ref.current;
        }
    }, [hitsAreTheSame, ref.current, hits]);

    if (!hitsAreTheSame) {
        ref.current = hits;
    }

    return(
        <>
            <PropertyGrid properties={hits} image_processed={true} />
            {hasNext && (
                <Button {...loadMoreBtnProp} disabled={!hasNext} onClick={onNext}>
                    {loadMoreBtnLabel}
                </Button>
            )}
        </>
    )
}

export default InfiniteHits;
