import React from "react"
import Typography from '@mui/material/Typography';
import TitleSkeleton
    from '../../../../../../../StarberryComponentsMui/components/Skeleton/TitleSkeleton'

const Title = (props) => {
    const { children, title, titleProps, titleType, customStats, nbHits, loading } = props

    let newTitle = title;
    var replaceData = {
            Properties: "Property",
            properties: "property"
        };
    if (nbHits === 1) {
        newTitle = title.replace(/Properties|properties/gi, function(matched){
                return replaceData[matched];
            });
    }
    return(
        <>
            {title &&
                <div className="search-panel__info__title">
                {loading ? (
                    <TitleSkeleton width="150px" />
                ) : (
                    <>
                        <Typography {...titleProps} classes={{root: `search-panel__info__title__title ${loading ? 'loading' : ''}`}}>
                            {("showResultsNumber" === titleType) && <>{customStats} </>}{newTitle}
                        </Typography> {children}
                    </>
                )}
                </div>
            }
        </>
    )
}

export default Title
