import * as React from 'react';
import { withSubtheme } from '../../../StarberryComponentsMui';
import Typography from '@mui/material/Typography';
import { StarberryIcons } from '../icons';

import defaults from './NoActionsDefaults';

const NoActions = withSubtheme((props) => {
    const {
        className,
        title,
        message,
        icon,

        titleProps,
        messageProps,
        iconProps,
        showMessage,
    } = props;

    return(
        <div className={className}>
            {icon && <StarberryIcons iconName={icon} {...iconProps} className="noActions-icon" />}
            {title && <Typography {...titleProps} >{title}</Typography>}
            {message && showMessage && <Typography {...messageProps}>{message}</Typography>}
        </div>
    )
}, 'noActions', defaults)

export default NoActions
