/**
// 1. Create a React component
const RangeSelect = () => {
  // return the DOM output
};

// 2. Connect the component using the connector
const CustomRangeSelect = connectRange(RangeSelect);

// 3. Use your connected widget
<CustomRangeSelect />
*/
// Ref: https://www.algolia.com/doc/api-reference/widgets/range-input/react/#full-example

import React from "react"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"

import { connectRange } from "react-instantsearch-dom"

const RangeSelectMax = props => {
    const {
        currentRefinement,
        // min,
        // max,
        // precision,
        refine,
        // attribute,
        options,
        className,
    } = props

    let itemKey, itemVal;
    let customOptions = options
    if (currentRefinement.min !== undefined) {
        customOptions = options.filter(x => {
            Object.keys(x).forEach(key => {
                itemKey = key
                itemVal = x[key]
            })
            if (!itemKey) return true
            if (parseInt(itemKey) > parseInt(currentRefinement.min)) return true
            return false
        })
    }

    return (
        <>
            <Select
                variant="outlined"
                displayEmpty={true}
                value={currentRefinement.max || ""}
                onChange={event =>
                    refine({
                        ...currentRefinement,
                        max: event.target.value
                    })
                }
                className={className}
            >
                {customOptions.map((item, i) => {
                    Object.keys(item).forEach(key => {
                        itemKey = key
                        itemVal = item[key]
                    })
                    return <MenuItem key={i} value={itemKey}>{itemVal}</MenuItem>
                })}
            </Select>
        </>
    )
}

const CustomRangeSelectMax = connectRange(RangeSelectMax)

export default CustomRangeSelectMax
