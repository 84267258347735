/**
// 1. Create a React component
const SearchBox = () => {
  // return the DOM output
};

// 2. Connect the component using the connector
const CustomSearchBox = connectSearchBox(SearchBox);

// 3. Use your connected widget
<CustomSearchBox />
*/

// Ref: https://www.algolia.com/doc/api-reference/widgets/search-box/react/#full-example

import React from "react"
import TextField from "@mui/material/TextField"
import FormJsonAutoCompleteField from "../../../../forms/jsonAutoComplete"

const SearchBox = props => {
    const { value, label, attribute, className, updateFilterParams } = props

    const onChange = (value) => {
        updateFilterParams(attribute, value);
    }
    return (
        <>
            {/*<TextField
                id="outlined-basic"
                label={label}
                defaultValue={value}
                onChange={event => {
                    updateFilterParams(attribute, event.target.value);
                }}
                // autoFocus
                classes={{root: className}}
                {...props}
            />*/}
            <FormJsonAutoCompleteField
                key={`2`}
                id="outlined-basic"
                label={label}
                variant="outlined"
                classes={{root: "globalForm-selectField"}}
                placeholder={`Search...`}
                fetchDataLink="areas"
                dropDownLabel="name"
                dropDownValue="slug"
                defaultValue={value.replace(RegExp('-','g'), ' ')}
                classes={{root: className}}
                {...props}
                onChange={onChange}
            />
        </>
    )
}

export default SearchBox
