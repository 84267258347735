/**
// 1. Create a React component
const SortBy = () => {
// return the DOM output
};

// 2. Connect the component using the connector
const CustomSortBy = connectSortBy(SortBy);

// 3. Use your connected widget
<CustomSortBy />
*/
// Ref: https://www.algolia.com/doc/api-reference/widgets/sort-by/react/#full-example

import React from "react"
import { navigate } from 'gatsby';
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import { createSearchUrl, loadSearchParams } from '../../functions';

const SortBy = (props) => {
    const { items, searchParams } = props;

    let value = '';
    if (searchParams.sortBy) {
        value = `${searchParams.sortBy}_${searchParams.order}`
    }

    const handleOnChange = (value) => {
        navigate(createSearchUrl(
            {
                ...loadSearchParams(searchParams),
                sortBy: (value.replace('_', '-'))
            },
            {
                useStatus: false,
                basePath: {
                    residential: 'properties',
                }
            }
        ))
    }

    return (
        <Select
            variant="outlined"
            displayEmpty={true}
            value={value || ""}
            onChange={event => handleOnChange(event.target.value)}
        >
            {items.map((item, i) => {
                return <MenuItem key={i} value={item.value}>{item.label}</MenuItem>
            })}
        </Select>
    )
}

export default SortBy
