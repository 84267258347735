/**
// 1. Create a React component
const InfiniteHits = () => {
  // return the DOM output
};

// 2. Connect the component using the connector
const CustomInfiniteHits = connectInfiniteHits(InfiniteHits);

// 3. Use your connected widget
<CustomInfiniteHits />
*/
// Ref: https://www.algolia.com/doc/api-reference/widgets/infinite-hits/react/#full-example

import React from "react";
import Button from "@mui/material/Button";
import PropertyGrid from "../../../../../PropertyGrid";

import { connectInfiniteHits } from "react-instantsearch-dom";

const InfiniteHits = ({
    hits,
    hasPrevious,
    refinePrevious,
    hasMore,
    refineNext,

    // custom passed props
    loadMoreBtnProp,
    loadPrevBtnProp,
    loadMoreBtnLabel,
    loadPrevBtnLabel
}) => {
    const ref = React.useRef(hits);

    const hitsAreTheSame = React.useMemo(() => {
        return hits.map((x, i) => {
            return x.crm_id === ref.current[i]?.crm_id;
        }).reduce((acc, o) => acc && o, true) && hits.length === ref.current.length;
    }, [hits, ref.current]);

    const result = React.useMemo(() => {
        if (!hitsAreTheSame) {
            return hits;
        } else {
            return ref.current;
        }
    }, [hitsAreTheSame, ref.current, hits]);

    if (!hitsAreTheSame) {
        ref.current = hits;
    }

    return(
        <>
            {
                hasPrevious &&
                <Button {...loadPrevBtnProp} disabled={!hasPrevious} onClick={refinePrevious}>{loadPrevBtnLabel}</Button>
            }
            <PropertyGrid properties={result} image_processed={true} />
            {
                hasMore &&
                <Button {...loadMoreBtnProp} disabled={!hasMore} onClick={refineNext}>{loadMoreBtnLabel}</Button>
            }
        </>
    )
}

const CustomInfiniteHits = connectInfiniteHits(InfiniteHits)

export default CustomInfiniteHits;
