export default {
    "props": {
        "searchProvider": "algolia",
        "showSaveSearch": true,
        "filterBtnProp": {
            "variant": "contained",
        },
        "loadMoreBtnProp": {
            "variant": "contained",
            "className": "load-more-btn",
        },
        "loadMoreBtnLabel": "Show more",
        "loadPrevBtnProp": {
            "variant": "contained",
            "className": "load-prev-btn",
        },
        "loadPrevBtnLabel": "Show previous",
        "titleType": "",
        "titleProps": {
            "component": "h1",
            "variant": "h4",
        },
        "titleStatsProps": {
            "component": "span",
            "variant": "caption",
        },
        "gridViewProps": {

        },
        "mapViewProps": {
            "edge": "end",
        },
        "searchTypeLinkProps": {
            "show": true,
            "showAsToggle": false,
        },
        "showClearFilters": true,
    },
    "sx": (theme) => ({
        "& .refine-submit": {
            "padding": "10px 26px",
            "margin": "0.5rem"
        },
        "& .price-range-filter": {
            "paddingLeft": "10px",
            "justifyContent": "left",
            [theme.breakpoints.down('lg')]: {
                "paddingLeft": "12px"
            }
        },
        "& .min-price-container": {
            "width": "100%"
        },
        "& .suggestionsContainer": {
            "position": "relative",
            "& > .MuiFormControl-root": {
                "width": "100%"
            },
            "& .suggestionsContainerOpen": {
                "position": "absolute",
                "zIndex": 998,
                "top": "50px",
                "left": 0,
                "right": 0,
            },
            "& .MuiInputLabel-formControl": {
                "lineHeight": "1"
            },
            "& .MuiInputBase-formControl input": {
                "padding": "11px 10px"
            },
            "& .suggestionsList": {
                "margin": 0,
                "padding": 0,
                "listStyleType": "none",
                "maxHeight": "200px",
                "overflowY": "scroll",
            },
            "& .suggestion": {
                "display": "block",
                "padding": "10px 15px",
                "&:hover": {
                    "cursor": "pointer",
                    "background": theme.palette.grey.grey3,
                },
            },
        },
        "& .search-topbar": {
            "display": "flex",
            "flexDirection": "row",
            "justifyContent": "space-between",
            "marginBottom": theme.spacing(2),
            "& .search-clear": {
                "display": "flex",
                "& .search-clear-btn": {
                    "padding": 0,
                    "&:hover": {
                        "background": "transparent",
                        "textDecoration": "underline",
                    },
                    "&:active": {
                        "background": "transparent",
                    },
                },
            },
            "& .search-switch": {
                "display": "flex",
                "flexDirection": "row",
                "justifyContent": "flex-end",
                "& a": {
                    "color": theme.palette.text.primary,
                    "marginRight": "8px",
                    "paddingRight": "8px",
                    "borderRight": `1px solid ${theme.palette.grey.grey3}`,
                    "fontSize": theme.bodySmall,
                    "lineHeight": "26px",
                    "&:last-child": {
                        "borderRight": "none",
                        "margin": 0,
                        "padding": 0,
                    },
                    "&.Mui-selected": {
                        "color": theme.palette.secondary.main,
                    },
                },
                "&.search-switch-toggle": {
                    "border": "1px solid rgba(0,0,0,0.2)",
                    "borderRadius": theme.shape.borderRadius,
                    "padding": "5px",
                    "& a": {
                        "color": theme.palette.text.primary,
                        "borderRight": "none",
                        "borderRadius": theme.shape.borderRadius,
                        "padding": "0 4px",
                        "&.Mui-selected": {
                            "background": theme.palette.primary.main,
                            "color": "#fff",
                            "padding": "0 4px",
                        },
                    },
                },
            },
        },
        "& .search-panel__btn": {
            "marginBottom": theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                "minWidth": "300px",
            },
            [theme.breakpoints.up('lg')]: {
                "display": "none",
            },
            "&.search-panel__btn__label": {
                "justifyContent": "space-between",
            }
        },
        "& .search-panel__filters-wrap": {
            "margin": theme.spacing(0, 0, 2, 0),
            "&.search-panel__filters__hidden": {
                [theme.breakpoints.down('md')]: {
                    "height": "0px",
                    "overflow": "hidden",
                    "visibility": "hidden",
                },
                [theme.breakpoints.up('md')]: {
                    "margin": theme.spacing(0, 0, 1, 0),
                },
            },
        },
        "& .search-panel__filters": {
            "display": "flex",
            "flexDirection": "column",
            "height": "auto",
            "transition": "height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            "overflow": "visible",
            "visibility": "visible",
            [theme.breakpoints.up('md')]: {
                "flexDirection": "row",
                "flexWrap": "wrap",
                "margin": theme.spacing(0, -1, 1, -1),
            },
            [theme.breakpoints.up('lg')]: {
                "flexWrap": "nowrap",
                // "margin": theme.spacing(0, -1, 2, -1),
            },
            // "&.search-panel__filters__hidden": {
            //     [theme.breakpoints.down('lg')]: {
            //         "height": "0px",
            //         "overflow": "hidden",
            //         "visibility": "hidden",
            //     },
            // },
            "& > div": { // these are input elements
                "marginBottom": theme.spacing(2),
                [theme.breakpoints.up('md')]: {
                    "flexBasis": `calc(50% - ${theme.spacing(2)})`,
                    "margin": theme.spacing(1),
                },
                [theme.breakpoints.up('lg')]: {
                    "flexBasis": "auto",
                    "flexGrow": "1",
                },
                "&.bedrooms-select": {
                    "width": "100px",
                },
            },
        },
        "& .search-panel__info": {
            "display": "flex",
            "flexDirection": "column",
            "marginBottom": theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                "flexDirection": "row",
                "justifyContent": "space-between",
                "alignItems": "center",
            },
            [theme.breakpoints.up('md')]: {
                "marginBottom": theme.spacing(4),
            },
            "& .search-panel__info__title": {
                [theme.breakpoints.up('sm')]: {
                    "paddingRight": theme.spacing(2),
                },
                "& .search-panel__info__title__title": {
                    "display": "inline",
                    "fontSize": theme.bodySmall,
                    "lineHeight": theme.lineHeightBSmall,
                    [theme.breakpoints.up('sm')]: {
                        "fontSize": theme.h6,
                        "lineHeight": theme.h6LineH,
                    },
                },
                "& .search-panel__info__stats": {
                    "paddingLeft": theme.spacing(0.5),
                    "whiteSpace": "nowrap",
                },
            },
            "& .search-panel__info__links": {
                "display": "flex",
                "flexDirection": "row",
                "& > div": {
                    "marginRight": theme.spacing(1),
                    "&:last-child": {
                        "marginRight": 0,
                    },
                    "& a": {
                        "&.Mui-selected": {
                            "& button svg": {
                                "color": theme.palette.secondary.main,
                            },
                        },
                    },
                },
            },
            "& .sortyby-select": {
                "& > div": { // parent div on the select
                    "height": "100%",
                    "padding": 0,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                    "border": "none",
                },
                "& .MuiInputBase-input": {
                    "paddingLeft": 0,
                    "background": "transparent",
                },
            },
        },
        "& .algolia_results": {
            "marginBottom": theme.spacing(4),
        },
        "& .load-prev-btn": {
            "display": "flex",
            "minWidth": "250px",
            "margin": theme.spacing(0, "auto", 4, "auto"),
        },
        "& .load-more-btn": {
            "display": "flex",
            "minWidth": "250px",
            "margin": "0 auto",
        },
        "& .custom-mapInfoBox": {
            "maxWidth": "260px",
            "padding": "20px 0px 10px 5px",
            "& .custom-mapInfoBox-imgLink": {
                "display": "block",
                "minHeight": "150px",
                "& img": {
                    "width": "100%",
                    "height": "auto",
                },
            },
            "& .custom-mapInfoBox-title": {
                "margin": "10px 0 10px 0",
                "fontSize": "16px",
                "& a": {
                    "color": theme.palette.text.primary,
                    "textDecoration": "none",
                    "&:hover": {
                        "textDecoration": "underline"
                    }
                }
            },
            "& .custom-mapInfoBox-price": {
                "margin": "10px 0 10px 0",
                "fontSize": "14px",
                "fontWeight": theme.typography.fontWeightBold,
            },
            "& .custom-mapInfoBox-list": {
                "display": "flex",
                "flexDirection": "row",
                "margin": 0,
                "padding": 0,
                "& li": {
                    "display": "flex",
                    "flexDirection": "row",
                    "alignItems": "center",
                    "marginRight": "15px",
                    "& .custom-mapInfoBox-listItem": {
                        "marginRight": "5px",
                    },
                },
            },
        },
        "& .gm-ui-hover-effect": {
            "top": "0!important",
            "right": "0!important",
        },
    })
}
