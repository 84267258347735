/**
// 1. Create a React component
const Stats = () => {
  // return the DOM output
};

// 2. Connect the component using the connector
const CustomStats = connectStats(Stats);

// 3. Use your connected widget
<CustomStats />
*/

// Ref: https://www.algolia.com/doc/api-reference/widgets/stats/react/#full-example

import React from "react"
import Typography from '@mui/material/Typography';
import { connectStats } from "react-instantsearch-dom"

const Stats = props => {
    const { nbHits, titleStatsProps, showOnlyNumber } = props

    if (showOnlyNumber) { // If we need to return just the number to add it into a title
        return nbHits
    } else {
        if (nbHits === 0) {
            return <Typography className="search-panel__info__stats" {...titleStatsProps}>(0)</Typography>
        } else {
            return <Typography className="search-panel__info__stats" {...titleStatsProps}>({nbHits} records found)</Typography>
        }
    }
}

const CustomStats = connectStats(Stats)

export default CustomStats
