/* eslint-disable import/first */
import React from "react"
import { withSubtheme } from "../../../../StarberryComponentsMui"
import GlobalLayout from "../../GlobalLayout";
import PropertyResultWrapperAlgolia from "./algolia"
import PropertyResultWrapperApi from "./api"
import {
    UserObjectStoreProvider,
    MyaccountStoreProvider
} from "../../../../services"

import defaults from './defaults';

const SearchResultPageThemeWrap = withSubtheme(props => {
    const {
        search_type,
        filterIconHeader,
        searchProvider,
    } = props

    const renderResult = () => {

    }
    return (
        <MyaccountStoreProvider>
            <UserObjectStoreProvider>
                {searchProvider === 'algolia' &&
                    <PropertyResultWrapperAlgolia search_type={search_type} filterIconHeader={filterIconHeader} />
                }
                {searchProvider === 'api' &&
                    <PropertyResultWrapperApi search_type={search_type} filterIconHeader={filterIconHeader} />
                }
            </UserObjectStoreProvider>
        </MyaccountStoreProvider>
    )
}, "propertyResultsPage", defaults)

const SearchResultPage = (props) => {
    return (
        <GlobalLayout>
            <SearchResultPageThemeWrap {...props} />
        </GlobalLayout>
    )
}

export default React.memo(SearchResultPage)
