/**
// 1. Create a React component
const SortBy = () => {
// return the DOM output
};

// 2. Connect the component using the connector
const CustomSortBy = connectSortBy(SortBy);

// 3. Use your connected widget
<CustomSortBy />
*/
// Ref: https://www.algolia.com/doc/api-reference/widgets/sort-by/react/#full-example

import React from "react"

import { connectSortBy } from "react-instantsearch-dom"

import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"

const SortBy = (props) => {
    const { currentRefinement, items, refine } = props;
    return (
        <Select
            variant="outlined"
            displayEmpty={true}
            value={currentRefinement || ""}
            onChange={event => refine(event.target.value)}
        >
            {items.map((item, i) => {
                return <MenuItem key={i} value={item.value}>{item.label}</MenuItem>
            })}
        </Select>
    )
}

const CustomSortBy = connectSortBy(SortBy)

export default CustomSortBy
