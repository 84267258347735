/* eslint-disable import/first */

import React from "react"
import { Router } from "@reach/router"

// Ref: https://www.gatsbyjs.com/docs/how-to/routing/client-only-routes-and-user-authentication/

// Importing individual components like this (rather than
// `import {...} from '@material-ui/core';` ) makes for more efficient
// bundling, in theory :
// see https://material-ui.com/guides/minimizing-bundle-size/

import { useTheme } from "../../core/StarberryComponentsMui"
import {
    MYACCOUNT_SEARCH_RESULT,
    MYACCOUNT_SEARCH_MAP_RESULT
} from "../../core/constants/urls";

import PrivateRoute from "../../core/components/MyAccount/hoc/PrivateRoute"
import SearchResultPage from "../../core/components/MyAccount/pages/properties"
import SearchResultMapPage from "../../core/components/MyAccount/pages/properties/map"
import PageHeaderHelmet from "../../core/components/pageHeader";

const MyAccountPages = () => {

    const theme = useTheme()

    return (
        <>
            <PageHeaderHelmet theme={theme} />
            <Router>
                <PrivateRoute
                    path={MYACCOUNT_SEARCH_MAP_RESULT}
                    component={SearchResultMapPage}
                    theme={theme}
                />
                <PrivateRoute
                    path={MYACCOUNT_SEARCH_RESULT}
                    component={SearchResultPage}
                    theme={theme}
                />
            </Router>
        </>
    )
}

export default MyAccountPages
