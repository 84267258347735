/**
// 1. Create a React component
const SearchBox = () => {
  // return the DOM output
};

// 2. Connect the component using the connector
const CustomSearchBox = connectSearchBox(SearchBox);

// 3. Use your connected widget
<CustomSearchBox />
*/

// Ref: https://www.algolia.com/doc/api-reference/widgets/search-box/react/#full-example

import React from "react"

import { connectSearchBox } from "react-instantsearch-dom"
import TextField from "@mui/material/TextField"

const SearchBox = props => {
    const { currentRefinement, refine, label, className } = props
    return (
        <>
            <TextField
                id="outlined-basic"
                label={label}
                variant="outlined"
                defaultValue={currentRefinement}
                onChange={event => refine(event.currentTarget.value)}
                // autoFocus
                classes={{root: className}}
            />
        </>
    )
}

const CustomSearchBox = connectSearchBox(SearchBox)

export default CustomSearchBox
